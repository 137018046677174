.ubuntu-light {
    font-family: "Ubuntu", sans-serif;
    font-weight: 300;
    font-style: normal;
  }
  
  .ubuntu-regular {
    font-family: "Ubuntu", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  
  .ubuntu-medium {
    font-family: "Ubuntu", sans-serif;
    font-weight: 500;
    font-style: normal;
  }
  
  .ubuntu-bold {
    font-family: "Ubuntu", sans-serif;
    font-weight: 700;
    font-style: normal;
  }
  
  .ubuntu-light-italic {
    font-family: "Ubuntu", sans-serif;
    font-weight: 300;
    font-style: italic;
  }
  
  .ubuntu-regular-italic {
    font-family: "Ubuntu", sans-serif;
    font-weight: 400;
    font-style: italic;
  }
  
  .ubuntu-medium-italic {
    font-family: "Ubuntu", sans-serif;
    font-weight: 500;
    font-style: italic;
  }
  
  .ubuntu-bold-italic {
    font-family: "Ubuntu", sans-serif;
    font-weight: 700;
    font-style: italic;
  }
  

  .playwrite-cu-dalala {
    font-family: "Playwrite CU", cursive;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
  }

  /* ubutu Roboto */

  .roboto-thin {
    font-family: "Roboto", sans-serif;
    font-weight: 100;
    font-style: normal;
  }
  
  .roboto-light {
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-style: normal;
  }
  
  .roboto-regular {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  
  .roboto-medium {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-style: normal;
  }
  
  .roboto-bold {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-style: normal;
  }
  
  .roboto-black {
    font-family: "Roboto", sans-serif;
    font-weight: 900;
    font-style: normal;
  }
  
  .roboto-thin-italic {
    font-family: "Roboto", sans-serif;
    font-weight: 100;
    font-style: italic;
  }
  
  .roboto-light-italic {
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-style: italic;
  }
  
  .roboto-regular-italic {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: italic;
  }
  
  .roboto-medium-italic {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-style: italic;
  }
  
  .roboto-bold-italic {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-style: italic;
  }
  
  .roboto-black-italic {
    font-family: "Roboto", sans-serif;
    font-weight: 900;
    font-style: italic;
  }
  

  .pt-serif-regular {
    font-family: "PT Serif", serif;
    font-weight: 400;
    font-style: normal;
  }
  
  .pt-serif-bold {
    font-family: "PT Serif", serif;
    font-weight: 700;
    font-style: normal;
  }
  
  .pt-serif-regular-italic {
    font-family: "PT Serif", serif;
    font-weight: 400;
    font-style: italic;
  }
  
  .pt-serif-bold-italic {
    font-family: "PT Serif", serif;
    font-weight: 700;
    font-style: italic;
  }
  

.noto-serif-oriya-regular {
  font-family: "Noto Serif Oriya", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.dancing-script-regular {
  font-family: "Dancing Script", cursive;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}